import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExamResultService } from './online-exam-result.service';
import swal from 'sweetalert2'
import { CSVExport } from '../Csv-export';

@Component({
  selector: 'app-online-exam-result',
  templateUrl: './online-exam-result.component.html',
  styleUrls: ['./online-exam-result.component.css'],
  providers: [ExamResultService]
})
export class OnlineExamResultComponent implements OnInit {
  coeName: any;
  batchName: any;
  sectionName: any;
  examName: any;
  examDate: any;
  examId: any;
  Subjects = [];
  tempTableHeader = [];
  ExamResults = [];
  FormatedExamResult = [];
  keys: string[] = [];
  isExamResultImportModal = false;
  fileInput: any;
  showLoader = false;
  fileReaded: any;
  average = [];
  searchText: any;
  alertDiv = false;
  sucessAlertMessage: any;
  isRole: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  examMarks: any;
  examGraphArray = [];
  lineChartData = [];
  lineChartLabels = [];
  isShowExamGraph = false;
  ExamStudents = []
  scholarName: any;
  examTotalMarks: any;
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  examGraphData = [];
  examTemplateId: any;
  patternId: any;
  sectionId: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private examResultService: ExamResultService) {

    console.log("constructor is called");

    this.isRole = localStorage.getItem("id_role");
    if (localStorage.getItem("isLogin") == "true") {
      this.activatedRoute.params.subscribe(params => {
        this.ExamStudents = JSON.parse(localStorage.getItem("exam-scholar"))
        this.examTemplateId = params['id_exam_template']
        this.patternId = params['id_pattern']
        this.sectionId = params['id_section']
        this.getExamResultData();
      });


    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  // testing methods
  ngOnChanges(changedvals: SimpleChanges) {
    for (let f in changedvals) {
      console.log("ngOnChanges = " + f)
    }
  }

  ngOnInit() {
    console.log("ngOnInit called ")
  }




  getExamResultData() {
    this.showLoader = true
    this.Subjects = [];
    this.examMarks = [];
    this.ExamResults = [];
    this.examGraphData = [];

    this.examResultService.getExamResultData(localStorage.getItem("email"), this.examTemplateId, this.patternId, this.sectionId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Subjects = data.subjects
          this.examMarks = data.exam_marks
          this.examGraphData = data.exam_graph
          this.coeName = data.coe_name;
          this.batchName = data.batch_name;
          this.sectionName = data.section_name;
          this.examName = data.exam_name;
          this.examMarks = data.exam_marks;
          this.examTotalMarks = data.exam_marks;
          this.examDate = data.exam_date
          if (this.isRole == "5") {
            let exams = data.exam_result

            for (let exam of exams) {
              if (exam.id_user == localStorage.getItem("id_user")) {
                this.ExamResults.push(exam);
              }
            }
          }
          else {
            this.ExamResults = data.exam_result;
            //console.log(this.ExamResults);
          }


          this.createFormattedData();
          this.isShowExamGraph = true;
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }

      },
      err => {
        this.showLoader = false
      }
    )
  }

  createFormattedData() {
    //.log(this.ExamResults.length)
    this.tempTableHeader = [];
    this.FormatedExamResult = [];
    var headingJson = {};
    headingJson["heading"] = "COE Roll Number"
    headingJson["key"] = "coe_roll_number"
    this.tempTableHeader.push(headingJson);
    headingJson = {};
    headingJson["heading"] = "Dakshana Roll Number"
    headingJson["key"] = "dakshana_roll_number"
    this.tempTableHeader.push(headingJson);
    headingJson = {};
    headingJson["heading"] = "Name"
    headingJson["key"] = "name"
    this.tempTableHeader.push(headingJson);
    headingJson = {};
    let tempExamResult = this.ExamResults;
    var check = false
    var totalMarks = 0;
    for (var i = 0; i < this.Subjects.length; i++) {
      headingJson["heading"] = this.Subjects[i].subject_name + " +ve marks"
      headingJson["key"] = this.Subjects[i].subject_name + " positive_mark"
      this.tempTableHeader.push(headingJson);
      headingJson = {};
      headingJson["heading"] = this.Subjects[i].subject_name + " -ve marks"
      headingJson["key"] = this.Subjects[i].subject_name + "negative_mark"
      this.tempTableHeader.push(headingJson);
      headingJson = {};
      headingJson["heading"] = this.Subjects[i].subject_name + " effective marks"
      headingJson["key"] = this.Subjects[i].subject_name + "effective_mark"
      this.tempTableHeader.push(headingJson);
      headingJson = {};
    }
    for (var i = 0; i < this.ExamResults.length; i++) {
      var json = {};
      var total = 0.0;
      for (var j = 0; j < this.ExamResults.length; j++) {
        // //.log(this.ExamResults[i].coe_roll_number == this.ExamResults[j].coe_roll_number)
        if (this.ExamResults[i].coe_roll_number == this.ExamResults[j].coe_roll_number) {

          json["coe_roll_number"] = this.ExamResults[j].coe_roll_number
          json["dakshana_roll_number"] = this.ExamResults[j].dakshana_roll_number
          json["name"] = this.ExamResults[j].name
          // json[this.ExamResults[j].subject_name + "positive_mark"] = this.ExamResults[j].positive_mark
          // json[this.ExamResults[j].subject_name + "total_marks"] = this.ExamResults[j].subject_total_marks
          json[this.ExamResults[j].subject_name + " +ve marks"] = this.ExamResults[j].positive_mark
          json[this.ExamResults[j].subject_name + " -ve marks"] = this.ExamResults[j].negative_mark
          json[this.ExamResults[j].subject_name + "effective_mark"] = this.ExamResults[j].effective_marks
          total = total + (this.ExamResults[j].effective_marks)
        }
      }
      if (i == 0) {
        json["total"] = total
        this.FormatedExamResult.push(json);
      }
      var check = false
      for (let data of this.FormatedExamResult) {
        if (data.coe_roll_number == this.ExamResults[i].coe_roll_number) {
          check = true;
          break;
        }
      }
      if (check == false) {
        json["total"] = total
        this.FormatedExamResult.push(json);
      }
    }
    headingJson = {};
    headingJson["heading"] = "Total"
    headingJson["key"] = "total"
    this.tempTableHeader.push(headingJson);
    if (this.FormatedExamResult.length > 0) {
      this.keys = Object.keys(this.FormatedExamResult[0])
      for (var i = 0; i < this.keys.length; i++) {
        for (let data of this.Subjects) {
          if ((data.name + "total_marks") == this.keys[i]) {
            this.keys.splice(i, 1)
          }
        }
      }
      //.log("keys-->" + this.keys);
      this.createAverage();
    }
    //.log(this.FormatedExamResult.length)
    //   let temp=this.FormatedExamResult[0];
    //   json.this.FormatedExamResult[0].forEach(element => {
    //     list.push(element.Id);
    // });
    this.genereteExamGraphData();
  }

  newExport() {
    this.isExamResultImportModal = true
  }
  hideAddEditModal() {
    this.isExamResultImportModal = false
  }

  csv2Array(fileInput: any) {
    this.fileInput = fileInput
  }
  saveExportData() {
    this.fileReaded = this.fileInput.target.files[0];
    let reader: FileReader = new FileReader();
    reader.readAsText(this.fileReaded);
    let lines = [];
    reader.onload = (e) => {
      let csv: string = reader.result as string;
      let allTextLines = csv.split(/\r|\n|\r/);
      let headers = allTextLines[0].split(',');


      for (let i = 0; i < allTextLines.length; i++) {
        let data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          let tarr = [];
          for (let j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }
          lines.push(tarr);

        }
      }
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_exam": this.examId,
        "excel": JSON.stringify(lines)
      }
      this.showLoader = true
      this.examResultService.exportExamResult(json).subscribe(
        data => {
          this.showLoader = false
          if (data.status_code == "0") {
            this.hideAddEditModal();
            this.getExamResultData();
          }
          else {
            this.showAlertDiv(data.status_message)
          }
        },
        err => {
          this.showLoader = false
          swal.fire("Something went wrong, Please try again later.")
          this.hideAddEditModal()
        }
      )
    }
  }

  newImport() {
    //.log(JSON.stringify(this.FormatedExamResult))
    var arrayToExport = [];
    if (this.FormatedExamResult.length > 0) {
      for (let data of this.FormatedExamResult) {
        var json = {}
        for (let key of this.keys) {
          json[key] = data[key];
        }
        arrayToExport.push(json);
      }
      CSVExport.downloadcsv(arrayToExport, "Exam Result");
    }
    else {
      swal.fire("Scholars not present.")
    }

  }

  createAverage() {
    this.average = [];
    var median = 0.0;
    var medianTotalArray = [];
    var mainMedianArray = [];
    for (let subject of this.Subjects) {
      var total = 0.0;
      medianTotalArray = [];
      for (let data of this.ExamResults) {
        if (subject.id_subject == data.id_subject) {
          medianTotalArray.push((+data.effective_marks));
          // mainMedianArray.push((+data.effective_marks));
          total = total + parseInt(data.effective_marks);
        }
      }
      medianTotalArray = medianTotalArray.sort((a, b) => a - b);
      //.log(JSON.stringify(medianTotalArray))
      let json = {
        "name": subject.subject_name,
        "subject_total_marks": subject.subject_mark,
        "mean": this.mean(medianTotalArray),
        "median": this.median(medianTotalArray),
        "mode": this.getModes(medianTotalArray),

      }
      this.average.push(json);
    }
    var totalMarks = 0.0;
    for (let data of this.average) {
      totalMarks = totalMarks + (+data.subject_total_marks)
    }
    for (let data of this.FormatedExamResult) {
      mainMedianArray.push(data.total)
    }
    //.log("mainMedianArray==>" + JSON.stringify(mainMedianArray))
    let totaJson = {
      "name": "Total",
      "subject_total_marks": totalMarks,
      "mean": this.mean(mainMedianArray),
      "median": this.median(mainMedianArray),
      "mode": this.getModes(mainMedianArray),
    }
    this.average.push(totaJson);

    //(this.average);
  }
  mean(numbers) {
    var total = 0, i;
    for (i = 0; i < numbers.length; i += 1) {
      total += numbers[i];
    }
    return total / numbers.length;
  }
  getModes(array) {
    var frequency = {}; // array of frequency.
    var maxFreq = 0; // holds the max frequency.
    var modes = [];

    for (var i in array) {
      frequency[array[i]] = (frequency[array[i]] || 0) + 1; // increment frequency.

      if (frequency[array[i]] > maxFreq) { // is this frequency > max so far ?
        maxFreq = frequency[array[i]]; // update max.
      }
    }

    for (var k in frequency) {
      if (frequency[k] == maxFreq) {
        modes.push((parseFloat(k)).toFixed(2));
      }
    }

    return modes;
  }

  median(numbers) {
    // median of [3, 5, 4, 4, 1, 1, 2, 3] = 3
    var median = 0, numsLen = numbers.length;
    numbers.sort((a, b) => a - b);

    if (
      numsLen % 2 === 0 // is even
    ) {
      // average of two middle numbers
      median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
    } else { // is odd
      // middle number only
      median = numbers[(numsLen - 1) / 2];
    }

    return median;
  }
  onBackPress() {
    this.router.navigateByUrl("/Online Exam Result")
  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  print() {
    let printContents, popupWin;

    printContents = document.getElementById('idprintPreviewDiv').innerHTML;
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'border:1px solid #000;' +
      'padding;0.5em;' +
      '}' +
      '</style>';
    htmlToPrint += printContents;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <html>
              <head>
                 
                  <style>
                      //........Customized style.......
                  </style>
              </head>
              <body onload="window.print();window.close()">${htmlToPrint}

              </body>
          </html>`
    );
    popupWin.document.close();
  }

  public lineChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }]
    }
  };


  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';


  back() {
    this.router.navigateByUrl('/Exams')
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  genereteExamGraphData() {
    var aboveNinty = 0
    var eightyToNinty = 0
    var seventyToEighty = 0
    var sixToSeventy = 0
    var fiftyToSixty = 0
    var fourtyToFifty = 0
    var thirtyToFourty = 0
    var twentyToThirty = 0
    var tenToTwenty = 0
    var belowTen = 0



    var seventyFiveToNinty = 0
    var fiftyToSeventyFive = 0
    var thirtyToFifty = 0
    var belowThirty = 0
    this.examGraphArray = [];

    for (let data of this.FormatedExamResult) {
      var getPercentage = ((parseFloat(data["total"])) * 100) / parseInt(this.examMarks);
      if (getPercentage > 90) {
        aboveNinty++;
      }
      else if (getPercentage <= 90 && getPercentage > 80) {
        eightyToNinty++;
      }
      else if (getPercentage <= 80 && getPercentage > 70) {
        seventyToEighty++;
      }
      else if (getPercentage <= 70 && getPercentage > 60) {
        sixToSeventy++;
      }
      else if (getPercentage <= 60 && getPercentage > 50) {
        fiftyToSixty++;
      }
      else if (getPercentage <= 50 && getPercentage > 40) {
        fourtyToFifty++;
      }
      else if (getPercentage <= 40 && getPercentage > 30) {
        thirtyToFourty++;
      }
      else if (getPercentage <= 30 && getPercentage > 20) {
        twentyToThirty++;
      }
      else if (getPercentage <= 20 && getPercentage > 10) {
        tenToTwenty++;
      }
      else if (getPercentage <= 10) {
        belowTen++;
      }

      // else if (getPercentage <= 90 && getPercentage > 75) {
      //   seventyFiveToNinty++;
      // }
      // else if (getPercentage <= 75 && getPercentage > 50) {
      //   fiftyToSeventyFive++;
      // }
      // else if (getPercentage <= 50 && getPercentage > 30) {
      //   thirtyToFifty++;
      // }
      // else if (getPercentage <= 30) {
      //   belowThirty++;
      // }
    }

    var totalStudents = this.FormatedExamResult.length;

    var aboveNintyPercentage = (aboveNinty * 100) / totalStudents
    var eightyToNintyPercentage = (eightyToNinty * 100) / totalStudents
    var seventyToEightyPercentage = (seventyToEighty * 100) / totalStudents
    var sixtyToSeventyPercentage = (sixToSeventy * 100) / totalStudents
    var fiftyToSixtyPercentage = (fiftyToSixty * 100) / totalStudents
    var fourtyToFiftyPercentage = (fourtyToFifty * 100) / totalStudents
    var thirtyToFourtryPercentage = (thirtyToFourty * 100) / totalStudents
    var twentyToThirtyPercentage = (twentyToThirty * 100) / totalStudents
    var tenToTwentyPercentage = (tenToTwenty * 100) / totalStudents
    var belowTenPercentage = (belowTen * 100) / totalStudents
    // var seventyFiveToNintyPercentage = (seventyFiveToNinty * 100) / totalStudents
    // var fiftyToSeventyFivePercentage = (fiftyToSeventyFive * 100) / totalStudents
    // var thirtyToFiftyPercentage = (thirtyToFifty * 100) / totalStudents
    // var belowThirtyPercentage = (belowThirty * 100) / totalStudents
    var json = {
      "tag": "Above 90 %",
      "value": aboveNintyPercentage
    }
    this.examGraphArray.push(json);
    json = {
      "tag": "80% to 90 %",
      "value": eightyToNintyPercentage
    }
    this.examGraphArray.push(json);
    json = {
      "tag": "70% to 80 %",
      "value": seventyToEightyPercentage
    }
    this.examGraphArray.push(json);
    json = {
      "tag": "60% to 70 %",
      "value": sixtyToSeventyPercentage
    }
    this.examGraphArray.push(json);

    json = {
      "tag": "50% to 60 %",
      "value": fiftyToSixtyPercentage
    }
    this.examGraphArray.push(json);
    json = {
      "tag": "40% to 50 %",
      "value": fourtyToFiftyPercentage
    }
    this.examGraphArray.push(json);
    json = {
      "tag": "30% to 40 %",
      "value": thirtyToFourtryPercentage
    }
    this.examGraphArray.push(json);

    json = {
      "tag": "20% to 30 %",
      "value": twentyToThirtyPercentage
    }
    this.examGraphArray.push(json);

    json = {
      "tag": "10% to 20 %",
      "value": tenToTwentyPercentage
    }
    this.examGraphArray.push(json);

    json = {
      "tag": "Below 10 %",
      "value": belowTenPercentage
    }
    this.examGraphArray.push(json);


    this.generatExamGraph();

    //.log(this.examGraphArray);
  }

  generatExamGraph() {
    this.lineChartLabels = [];
    this.lineChartData = [];
    // this.lineChartLabels.push("Above 90 %")
    // this.lineChartLabels.push("75% to 90 %")
    // this.lineChartLabels.push("50% to 75 %")
    // this.lineChartLabels.push("30% to 50 %")
    // this.lineChartLabels.push("Below 30 %")
    var examData = []
    for (let data of this.Subjects) {
      examData = [];
      for (let dataOne of this.examGraphData) {
        if (data.subject_name == dataOne.subject) {
          examData.push(parseInt(dataOne.value))
        }
      }
      this.lineChartData.push({ data: examData, label: data.subject_name })
    }

    for (let data of this.examGraphArray) {
      this.lineChartLabels.push(data.tag)
    }
    var examData = []
    for (let data of this.examGraphArray) {
      examData.push(parseInt(data.value))
    }

    this.lineChartData.push({ data: examData, label: 'Total' })
    this.isShowExamGraph = true
    //.log(this.lineChartData)
  }

  downloadExamResultTemplate() {
    var arrayToExport = [];
    if (this.ExamStudents.length > 0) {
      for (let data of this.ExamStudents) {
        let json = {
          "CoE Roll Number": data.coe_roll_number,
          "Dakshana Roll Number": data.dakshana_roll_number,
          "Subject": data.subjects,
          "Positive Mark": "",
          "Negative Mark": "",
          "Effective Mark": "",
          "id_subject": data.id_subject
        }
        arrayToExport.push(json);
      }
      CSVExport.downloadcsv(arrayToExport, "Exam_Result" + Math.floor(1000 + Math.random() * 9000));
    }
    else {
      swal.fire("There is no record to export.")
    }
  }
  sort(property) {
    //.log("property-->" + property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.FormatedExamResult.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  viewScholarDetails(coeRoleNumber) {
    localStorage.setItem("exam_name", this.examName)
    let url = '/online-exam-result-details/' + this.examTemplateId + "/" + coeRoleNumber
    this.router.navigateByUrl(url);

  }


  resetExam(coeRollNumber) {
    swal.fire({
      title: '',
      text: "This action cannot be undone.Are you sure you want to reset this scholar exam?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, reset it!'
    }).then((result) => {
      if (result.value) {
        if (navigator.onLine) {
          this.showLoader = true;
          this.examResultService.ScholarExamReset(localStorage.getItem("email"), this.examTemplateId, coeRollNumber).subscribe(
            (data: any) => {
              this.showLoader = false;

              if (data["status_code"] == 0) {
                this.getExamResultData();
              }
              else {
                this.onBackPress();
                swal.fire(data["status_message"]);
              }
            },
            err => {
              this.showLoader = false;
              swal.fire("Something went wrong.")
            }

          )
        }
        else {
          swal.fire("Please check your internet connection.")
        }

      }
    })





  }

  downloadCsv() {
    var arrayToExport = [];
    if (this.FormatedExamResult.length > 0) {
      for (let data of this.FormatedExamResult) {
        var json = {}
        for (let key of this.keys) {

          json[key] = data[key]

        }

        arrayToExport.push(json);
      }

      CSVExport.downloadcsv(arrayToExport, "Exam Result" + "_" + this.coeName + "_" + this.batchName + "_" + this.sectionName + "_" + this.examName);

    }
  }
}
