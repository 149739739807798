import { Component } from '@angular/core';
import { MasterService } from './master.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
@Component({
    selector: 'master',
    templateUrl: 'master.component.html',
    styleUrls: ['master.component.scss'],
    providers: [MasterService]
})
export class MasterComponent {
    showLoader: boolean;
    maserDataArray: any;
    constructor(private masterService: MasterService, private router: Router) {
        this.showLoader = false;
        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1"
            || localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "3") {
            this.getAllMasterData();
        }
        else {
            this.router.navigateByUrl('/');
        }
    }
    getAllMasterData() {
        this.showLoader = true;
        this.masterService.getAllMasterData(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.maserDataArray = data["master_data"];
                }
                else {
                    swal.fire(data["status_message"]);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    selectedMasterView(name) {
        let tempUrl = name.toLowerCase();
        this.router.navigateByUrl('/' + tempUrl)
    }
}
